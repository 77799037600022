import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {GlobalState} from "../utils/redux/ReduxUtils";
import {connect} from "react-redux";
import * as Consts from '../utils/Consts'
import {
    Admin,
    BooleanField, CreateButton, DateField, FunctionField,
    NumberField,
    Resource, SearchInput, useCreate,
    useGetOne,
    useRecordContext,
    useUpdate
} from 'react-admin';
import restProvider from 'ra-data-simple-rest';
// in posts.js
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    BooleanInput,
    TextField,
    EditButton,
    SaveButton,
    TextInput,
    NumberInput,
    Toolbar
} from 'react-admin';
import UserIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import BoltIcon from '@mui/icons-material/Bolt';
import authProvider from "../utils/authProvider";
import {fetchUtils} from "ra-core";

const httpClient = (url: any, options?: fetchUtils.Options) => {
    let opts = options ? options : {} as fetchUtils.Options;
    let headers: HeadersInit = opts.headers ? new Headers(opts.headers) : new Headers({Accept: 'application/json'});
    const token = localStorage.getItem('authToken') || '';
    headers.set('auth', `${token}`);
    opts.headers = headers;
    return fetchUtils.fetchJson(url, opts);
};
const realProvider = restProvider(Consts.API_ROOT, httpClient);

const HomeScreen = (props: any) => {
    return (
        <div className='col-12'>
            <Admin dataProvider={realProvider} authProvider={authProvider}>
                <Resource name="user" list={UserList} edit={UserEdit} options={{label: 'Пользователи'}}
                          icon={UserIcon}/>
                <Resource name="chargingpoint" list={ChargingPointList} edit={ChargingPointEdit}
                          create={ChargingPointCreate}
                          options={{label: 'Станции'}}/>
                <Resource name="payment" list={PaymentsList} edit={PaymentEdit} options={{label: 'Платежи'}}
                          icon={PaymentIcon}/>
                <Resource name="transaction" list={TransactionsList} edit={TransactionEdit}
                          options={{label: 'Транзакции'}} icon={BoltIcon}/>
            </Admin>
        </div>
    )
}


export const UserList = (props: any) => (
    <List perPage={25}{...props}>
        <Datagrid>
            <TextField source="name" label={'Имя'}/>
            <TextField source="surname" label={'Фамилия'}/>
            <TextField source="email" label={'Email'}/>
            <TextField source="phone" label={'Телефон'}/>
            <TextField source="car_brand" label={'Бренд автомобиля'}/>
            <TextField source="car_model" label={'Модель автомобиля'}/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const TransactionsList = (props: any) => (
    // filters={[<SearchInput name={'keyword'} source={'name'}/>]}
    <List perPage={25} {...props}>
        <Datagrid>
            <UserLinkField label='Пользователь'/>
            <NumberField source="booking_cost" label={'Стоимость бронирования, ₽'}/>
            <NumberField source="charging_cost" label={'Стоимость зарядки, ₽'}/>
            <BooleanField source="is_finished" label={'Закончена'}/>
            <BooleanField source="is_paid" label={'Оплачена'}/>
            <TextField source="time_created" label={'Момент начала'}/>
            <TextField source="time_finished" label={'Момент окончания'}/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const TransactionEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm toolbar={false}>
            <TextInput disabled source="id" className='text-input-wrapper'/>
            <TextInput disabled source="cp_id" label={'id станции'} className='text-input-wrapper'/>
            <NumberInput disabled source="booking_cost" label={'Стоимость бронирования, ₽'}/>
            <NumberInput disabled source="charging_cost" label={'Стоимость зарядки, ₽'}/>
            <BooleanInput disabled source="is_finished" label={'Закончена'}/>
            <BooleanInput disabled source="is_paid" label={'Оплачена'}/>
            <TextInput disabled source="booking_start" label={'Момент начала бронирования'} className='text-input-wrapper'/>
            <TextInput disabled source="booking_end" label={'Момент окончания бронирования'} className='text-input-wrapper'/>
            <TextInput disabled source="charging_start" label={'Момент начала зарядки'} className='text-input-wrapper'/>
            <TextInput disabled source="charging_end" label={'Момент окончания зарядки'} className='text-input-wrapper'/>
            <TextInput disabled source="time_created" label={'Момент начала'} className='text-input-wrapper'/>
            <TextInput disabled source="time_finished" label={'Момент окончания'} className='text-input-wrapper'/>
        </SimpleForm>
    </Edit>
};
export const UserLinkField = (props: any) => {
    const record = useRecordContext(props);
    return record ?
        <a href={`/user/${record.user.id}`}>+{record.user.phone}</a>
        : null;
}
export const PaymentsList = (props: any) => (
    <List perPage={25} {...props} >
        <Datagrid>
            <FunctionField label='Номер транзакции' render={(record: any, source: any) => '...' + record.tx_id.slice(-10)}/>
            <UserLinkField label='Пользователь'/>
            <TextField source="acquier_tx_id" label={'Номер платежа в CP'}/>
            <TextField source="amount" label={'Сумма, ₽'}/>
            <TextField source="success" label={'Успешно'}/>
            <TextField source="reverted" label={'Возврат'}/>
            <TextField source="message" label={'Сообщение'}/>
            <DateField showTime source='timestamp' label={'Дата'}/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const PaymentEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm toolbar={false}>
            <TextInput disabled source="id" className='text-input-wrapper'/>
            <TextInput source="tx_id" label={'Номер транзакции'} className='text-input-wrapper'/>
            <TextInput source="acquier_tx_id" label={'Номер платежа в CP'} className='text-input-wrapper'/>
            <NumberInput source="amount" label={'Сумма'} />
            <BooleanInput source="success" label={'Успешно'}/>
            <BooleanInput source="reverted" label={'Возврат'}/>
            <TextInput source="message" label={'Сообщение'} className='text-input-wrapper'/>
        </SimpleForm>
    </Edit>
};

export const ChargingPointLastTxInfoField = (props: any) => {
    const record = useRecordContext(props);
    return record.last_tx ?
        <a href={`/transaction/${record.last_tx?.id}`}>{record.last_tx.time_finished}</a>
        : null;
}
export const ChargingPointList = (props: any) => (
    <List perPage={25} {...props} actions={<Toolbar>
        <CreateButton/>
    </Toolbar>}>
        <Datagrid>
            <TextField source="address" label={'Адрес'}/>
            <TextField source="lat"/>
            <TextField source="long"/>
            <TextField source="connector_group_name" label='Мультиконнектор'/>
            <NumberField source="booking_minute_price" label={'Стоимость бронирования'}/>
            <NumberField source="charging_minute_price" label={'Стоимость зарядки'}/>
            <BooleanField source="is_available" label={'Доступна'}/>
            <BooleanField source="is_booked" label={'Занята'}/>
            <BooleanField source="is_deleted" label={'Удалена'}/>
            <ChargingPointLastTxInfoField label={'Посл. транзакция'}/>
            <EditButton/>
        </Datagrid>
    </List>
);
export const ChargingPointEdit = (props: any) => {
    const {id} = useParams();
    const {isLoading, data} = useGetOne("chargingpoint", {id});
    const [update, {isLoading: isSubmitting}] = useUpdate();
    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        update(
            "chargingpoint",
            {id, data},
            {
                onSuccess: () => {
                    navigate('/chargingpoint');
                }
            }
        );
    };

    return <Edit {...props}>
        <SimpleForm onSubmit={onSubmit} toolbar={<Toolbar {...props} >
            <SaveButton/>
        </Toolbar>}>
            <TextInput disabled source="id" className='text-input-wrapper'/>
            <TextInput source="address" label={'Адрес'} className='text-input-wrapper'/>
            <NumberInput source="lat" label={'Широта'}/>
            <NumberInput source="long" label={'Долгота'}/>
            <NumberInput source="booking_minute_price" label={'Стоимость минуты брони'}/>
            <NumberInput source="charging_minute_price"
                         label={'Стоимость минуты зарядки / стоимость кВт*ч (если выбрана настройка)'}/>
            <NumberInput source="connector_id" label={'Дополнительный идентификатор зарядки (номер коннектора)'} className='text-input-wrapper'/>
            <BooleanInput source="using_kwt_fare" label={'Использовать расчёт по кВт*ч'}/>
            <BooleanInput source="is_available" label={'Подключена к системе'}/>
            <BooleanInput source="is_booked" label={'Забронирована пользователем'}/>
            <div>Станции с одинаковым именем мультиконнектора будут показаны пользователю как одна с возможностью выбора коннектора:</div>
            <TextInput source="connector_group_name" label='Имя мультиконнектора' className='text-input-wrapper'/>
            <BooleanInput source="is_deleted" label={'Удалена из системы'}/>
        </SimpleForm>
    </Edit>
};

export const ChargingPointCreate = (props: any) => {
    const [create, {isLoading: isSubmitting}] = useCreate();
    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        console.log("onSubmit", data)
        create(
            "chargingpoint",
            {data},
            {
                onSuccess: () => {
                    navigate('/chargingpoint');
                }
            }
        );
    };
    return <Create {...props}>
        <SimpleForm onSubmit={onSubmit}>
            <TextInput source="address" label={'Адрес'} className='text-input-wrapper'/>
            <NumberInput source="lat" label={'Широта'}/>
            <NumberInput source="long" label={'Долгота'}/>
            <NumberInput source="booking_minute_price" label={'Стоимость минуты брони'}/>
            <NumberInput source="charging_minute_price"
                         label={'Стоимость минуты зарядки / стоимость кВт*ч (если выбрана настройка)'}/>
            <NumberInput source="connector_id" label={'Дополнительный идентификатор зарядки (номер коннектора)'}/>
            <div>Станции с одинаковым именем мультиконнектора будут показаны пользователю как одна с возможностью выбора коннектора:</div>
            <TextInput source="connector_group_name" label='Имя мультиконнектора' className='text-input-wrapper'/>
            <BooleanInput source="using_kwt_fare" label={'Использовать расчёт по кВт*ч'}/>
        </SimpleForm>
    </Create>
};

const UserTitle = () => {
    const record = useRecordContext();
    return <span>Пользователь {record ? `"${record.name || ''} ${record.surname || ''}"` : ''}</span>;
};

export const UserEdit = (props: any) => {
    return <Edit title={<UserTitle/>} {...props}>
        <SimpleForm toolbar={false}>
            <TextInput disabled source="id" className='text-input-wrapper'/>
            <TextInput source="name" label={'Имя'} className='text-input-wrapper'/>
            <TextInput source="surname" label={'Фамилия'} className='text-input-wrapper'/>
            <TextInput source="phone" label={'Номер телефона'} className='text-input-wrapper'/>
            <TextInput source="email" type={'email'} className='text-input-wrapper'/>
            <TextInput source="license_plate" label={'Водит. удостоверение'} className='text-input-wrapper'/>
            <TextInput source="car_brand" label={'Бренд авто'} className='text-input-wrapper'/>
            <TextInput disabled source="cards" multiline options={{multiline: true}} className='text-input-wrapper'
                       label={'Карты (последние цифры + тип)'}
                       format={(v: any[]) => v.map(c => c.last_digits + ' ' + c.card_type).join(" , ")}/>
        </SimpleForm>
    </Edit>
};


function mapStateToProps(global: GlobalState) {
    return {}
}

export default connect(mapStateToProps)(HomeScreen)
