import React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {useLocation} from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";

function App(props: any) {

  const location = useLocation();

  return (
    <div className="App">
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <HomeScreen/>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
