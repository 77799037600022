import * as Consts from "./Consts";

const authProvider = {
    // called when the user attempts to log in
    login: (params: any) => {
        const login: string = params.username
        const password: string = params.password
        return fetch(Consts.API_ROOT + "/login", {
            method: 'POST',
            body: JSON.stringify({
                login,
                password
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(res =>
            localStorage.setItem('authToken', res.access_token)
        );
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('authToken');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (status: number) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('authToken');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('authToken')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;
