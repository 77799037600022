import {EMPTY_GLOBAL_STATE, GlobalState} from "./redux/ReduxUtils";


export const saveState = (s: GlobalState) => {
    localStorage.setItem('state', JSON.stringify(s));
    localStorage.setItem('state_version', "1");
}

export function loadState(): GlobalState | undefined {
    const stateStrOpt = localStorage.getItem('state');
    if (stateStrOpt) {
        const emptyStateCopy = {...EMPTY_GLOBAL_STATE}
        return Object.assign(emptyStateCopy, JSON.parse(stateStrOpt));
    } else return undefined;
}
