import {applyMiddleware, createStore} from 'redux'
import thunk from "redux-thunk";
import {ReducerAction, UPDATE_ALL_ITEMS, UPDATE_USER} from "./types";
import {User} from "firebase/auth";
import * as LocalStorageUtils from '../LocalStorageUtils'

export interface GlobalState {
    firebaseUser?: User
}

export const EMPTY_GLOBAL_STATE: GlobalState = {
}
const initialGlobalStateFromLocalStorage: GlobalState = LocalStorageUtils.loadState() || EMPTY_GLOBAL_STATE;

const wrapStateUpdate = (state: GlobalState) => {
    LocalStorageUtils.saveState(state);
    return state;
}

function localStorageStateSavingReducer(state: GlobalState = EMPTY_GLOBAL_STATE, action: ReducerAction) {
    return wrapStateUpdate(myReducer(state, action));
}

function myReducer(state: GlobalState = EMPTY_GLOBAL_STATE, action: ReducerAction) {
    switch (action.type) {
        case UPDATE_ALL_ITEMS:
            return {...state, allItems: action.payload};
        case UPDATE_USER:
            return {...state, firebaseUser: action.payload}
        default:
            return state;
    }
}

export const globalStore = createStore(
    localStorageStateSavingReducer,
    initialGlobalStateFromLocalStorage,
    applyMiddleware(thunk)
);

