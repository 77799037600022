import {User} from "firebase/auth";

export const UPDATE_ALL_ITEMS = 'UPDATE_ALL_ITEMS'
export const UPDATE_USER = 'UPDATE_USER'

export interface UpdateAllItemsAction {
    type: typeof UPDATE_ALL_ITEMS
    payload: string[]
}

export interface UpdateFirebaseUserAction {
    type: typeof UPDATE_USER
    payload?: User
}

export type ReducerAction =
    | UpdateAllItemsAction
    | UpdateFirebaseUserAction

